import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { APIService } from 'src/app/core/services/api.service';
import { AppConfigService } from 'src/app/core/services/app-config.service';
import { CashoutService } from 'src/app/core/services/cashout.service';
import { LanguageService } from 'src/app/core/services/language.service';
import { CashoutStore } from 'src/app/core/state/cashout/cashout.store';
import { MyBetsCouponStatus } from 'src/app/modules/my-bets/models/my-bets-enums.model';
import { BetDetailsModel, BetLiveDetailsModel, RecentBetModel } from 'src/app/modules/my-bets/models/my-bets.model';
import { MyBetsProductInterface } from 'src/app/modules/my-bets/services/interfaces/my-bets-product-interface';
import {
  mapCouponStatus,
  mapGetBetDetailsCallResponse,
  mapGetMyBetsCallResponse,
} from 'src/app/modules/my-bets/services/my-bets-api-helpers';
import { MyBetsLiveService } from 'src/app/modules/my-bets/services/my-bets-live-service';
import { APISettings, APIType } from 'src/app/shared/models/api.model';
import { BetCashoutModel, CashoutModel, CashoutSource } from 'src/app/shared/models/cashout.model';
import { MyBetsStore } from 'src/app/modules/my-bets/state/my-bets.store';

@Injectable({
  providedIn: 'root',
})
export class SportsMyBetsService implements MyBetsProductInterface {
  private readonly _SPORT_APP_CONFIG = this.appConfigService.get('sports');

  constructor(
    private readonly apiService: APIService,
    private readonly appConfigService: AppConfigService,
    private readonly cashoutService: CashoutService,
    private readonly cashoutStore: CashoutStore,
    private readonly languageService: LanguageService,
    private readonly myBetsLiveService: MyBetsLiveService,
    private readonly myBetsStore: MyBetsStore
  ) {}

  getMyBetsCall(couponStatus: MyBetsCouponStatus): Observable<any> {
    const status = mapCouponStatus(couponStatus);
    const idChannel = this.appConfigService.get('brandId');
    const includeUsers = true;
    const lang = this.languageService.selectedLanguage ? this.languageService.selectedLanguage.language : 'en';
    const pageSize = (this._SPORT_APP_CONFIG.myBets.couponCount as number) + 1;

    return this.apiService.get<any>(
      APIType.SportsbookBetsearch,
      `couponsListNoDate/${status}/${includeUsers}/${pageSize}/${lang}/${idChannel}`
    );
  }

  getMyBetsCount(couponStatus: MyBetsCouponStatus) {}

  getLiveDataCall(): Observable<BetLiveDetailsModel[]> {
    return this.apiService
      .get(
        APIType.SportsbookFeed,
        `api/feeds/live/overview/general/${this.languageService.selectedLanguage.language}`,
        new APISettings({
          noAuthToken: true,
        })
      )
      .pipe(map(data => this.myBetsLiveService.parseSportsLiveEvents(data)));
  }

  getBetDetails(bet: RecentBetModel): Observable<any> {
    return this.apiService.get<any>(
      APIType.SportsbookBetsearch,
      `byCode/${bet.couponCode}/language/${this.languageService.selectedLanguage.language}`
    );
  }

  parseGetMyBetsCallResponse = (response: any, isOpen: boolean = true): RecentBetModel[] => mapGetMyBetsCallResponse(response, isOpen);

  parseBetDetailsResponse = (response: any): BetDetailsModel => mapGetBetDetailsCallResponse(response);

  retrieveCashoutStatusesForOpenBets(bets: RecentBetModel[]): void {
    if (bets.length > 0 && this._SPORT_APP_CONFIG.cashout.enabled && this.cashoutService.isWhitelistedUserType) {
      const filteredBets = this.appConfigService.get('sports').freebets.allowCashout
        ? bets
        : bets.filter(bet => !bet.hasFreebetsVoucher && !bet.isFlexicut).map(bet => bet.couponCode);

      this.apiService
        .post<any>(APIType.SportsbookCashout, 'cashout/bulkcashoutvalues', filteredBets)
        .pipe(
          tap(response => {
            const cashoutMap: Map<string, BetCashoutModel> = new Map();
            response.forEach(cashoutData => {
              const betCashout: BetCashoutModel = this.cashoutService.parseBetCashoutResponse(cashoutData);
              cashoutMap.set(betCashout.serverData.CouponCode, betCashout);
            });

            if (cashoutMap.size > 0) {
              bets.forEach(bet => {
                const betCashout = cashoutMap.get(bet.couponCode);
                if (betCashout) {
                  this.cashoutStore.addCashoutData(
                    new CashoutModel({
                      betCashout,
                      betFinalState: bet.betFinalState,
                      cashoutSource: CashoutSource.RecentBets,
                      couponCode: bet.couponCode,
                      userId: bet.userId,
                      timeOfRetrieval: Date.now(),
                    })
                  );
                }
              });
            }
          })
        )
        .subscribe();
    }
  }
}
